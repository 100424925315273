/* import __COLOCATED_TEMPLATE__ from './ticket-links-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { trackedFunction } from 'ember-resources/util/function';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';

export default class TicketLinksSideDrawer extends Component {
  @service appService;
  @service intl;
  @service intercomEventService;
  @service inboxApi;
  @service router;
  @service session;
  @tracked showSideDrawer = false;
  @tracked showErrors = false;
  @tracked ticketTypes = [];

  constructor() {
    super(...arguments);

    if (this.args.slot && this.args.slot.isNew && !this.args.slot.hasConfigured) {
      this.args.slot.hasConfigured = true;
      this.openSideDrawer();
    }
    taskFor(this.loadTicketTypes).perform();
  }

  get app() {
    return this.appService.app;
  }

  get hasErrors() {
    return !!this.ticketLinks.toArray().some((link) => link.validations.isInvalid);
  }

  get ticketTypeOptions() {
    return this.ticketTypes
      .filter((ticketType) => ticketType.category === 'request')
      .map((ticketType) => {
        return {
          text: ticketType.name,
          value: ticketType.id,
          isDisabled: this.selectedTicketLinks.includes(ticketType.id),
        };
      });
  }

  get selectedTicketLinks() {
    return this.ticketLinks.map((link) => link.ticketTypeId);
  }

  get ticketLinks() {
    return this.args.slot.ticketLinks;
  }

  get hasNoLinks() {
    return this.ticketLinks.length === 0;
  }

  get hasOnlyOneLink() {
    return this.ticketLinks.length === 1;
  }

  get cancelButtonText() {
    return this.args.slot.isNew
      ? this.intl.t('messenger.add-apps.external-links.side-drawer-remove-app')
      : this.intl.t('messenger.add-apps.ticket-links.side-drawer-cancel');
  }

  get showingEmptyLink() {
    return this.hasOnlyOneLink && this.ticketLinks.lastObject.validations.isInvalid;
  }

  get isLoading() {
    return taskFor(this.loadTicketTypes).isRunning;
  }

  ticketTypeName(ticketTypeId) {
    return this.ticketTypeOptions.find((option) => option.value === ticketTypeId)?.text;
  }

  @action
  openSideDrawer() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: `ticket_links`,
      place: 'messenger_settings_ticket_links_side_drawer',
    });
    if (this.hasNoLinks) {
      this.addTicketLink();
    }
    this.showSideDrawer = true;
  }

  @action
  done() {
    if (this.hasErrors) {
      this.showErrors = true;
    } else {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'done',
        object: `ticket_links`,
        place: 'messenger_settings_ticket_links_side_drawer',
      });
      this.showErrors = false;
      this.showSideDrawer = false;
    }
  }

  @action
  cancel() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'canceled',
      object: `ticket_links`,
      place: 'messenger_settings_ticket_links_side_drawer',
    });
    this.showSideDrawer = false;
    this.clearTicketLinks();
    if (this.args.slot.isNew) {
      this.args.removeSlot(this.args.slot);
    }
  }

  @action
  resetTicketLinkDisplayOrder(reorderedLinks) {
    this.args.slot.resetTicketLinkDisplayOrder(reorderedLinks);
  }

  @action
  addTicketLink() {
    this.args.slot.addTicketLink({});
  }

  @action
  removeTicketLink(link) {
    this.args.slot.removeTicketLink(link);
  }

  @action
  selectIcon(link, icon) {
    link.icon = icon;
  }

  @action selectTicketType(link, ticketTypeId) {
    link.ticketTypeId = ticketTypeId;
    link.ticketTypeName = this.ticketTypeName(ticketTypeId);
  }

  @action
  openTicketType(link) {
    if (!link.ticketTypeId) {
      return;
    }
    let url = this.router.urlFor(
      'apps.app.settings.ticket-data.detail',
      this.session.workspace,
      link.ticketTypeId,
    );
    safeWindowOpen(url);
  }

  @action
  openTicketSettings() {
    let url = this.router.urlFor('apps.app.settings.ticket-data', this.session.workspace);
    safeWindowOpen(url);
  }

  ticketTypesLoader = trackedFunction(this, async () => {
    return await this.inboxApi.listTicketTypes();
  });

  clearTicketLinks() {
    this.ticketLinks.forEach((link) => link?.rollbackAttributes());
  }

  @task
  *loadTicketTypes() {
    this.ticketTypes = yield this.inboxApi.listTicketTypes();
  }
}
