/* import __COLOCATED_TEMPLATE__ from './brand-picker.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';

export default class BrandPicker extends Component {
  @service store;
  @service intercomEventService;
  @service notificationsService;
  @service helpCenterService;
  @service appService;
  @service intl;
  @tracked showModal = false;
  @tracked showAllDomains = false;

  previousActiveBrand = this.args.activeBrand;
  defaultBrand = this.args.brands.findBy('isDefault');
  defaultMobileBrand = this.args.brands.findBy('defaultMobileBrand');

  get app() {
    return this.appService.app;
  }

  get hasCustomBrands() {
    return this.args.brands.length > 1;
  }

  get isCreating() {
    return this.args.activeBrand.isNew;
  }

  get brandsForDropdown() {
    let isPickingForMobile = this.args.isMobilePlatform;
    let component = 'messenger-settings/look-and-feel/multibrand/brand-picker-item';
    let customBrands = this.args.brands.reject(
      (brand) => brand.isDefault || brand.defaultMobileBrand,
    );
    let platformBrands = isPickingForMobile
      ? customBrands.filter((brand) => brand.isMobile)
      : customBrands.filter((brand) => !brand.isMobile);

    let defaultBrandListItem = {
      text: isPickingForMobile ? this.defaultMobileBrand.brandName : this.defaultBrand.brandName,
      value: isPickingForMobile ? this.defaultMobileBrand : this.defaultBrand,
      component,
    };
    let customBrandListItems = platformBrands
      .map((brand) => {
        return {
          text: brand.brandName,
          value: brand,
          component,
        };
      })
      .sortBy('value.brandName');

    let dropdownList = [defaultBrandListItem, ...customBrandListItems];
    return [{ items: dropdownList }];
  }

  get saveButtonTooltip() {
    if (!this.args.activeBrand.isValid) {
      if (this.args.activeBrand.brandNameError) {
        return this.args.isMobilePlatform
          ? 'Please add a brand name and a Mobile app ID'
          : 'Please add a brand name and at least one valid domain';
      } else if (this.args.activeBrand.mobileBundleIdError) {
        return this.args.activeBrand.mobileBundleIdError;
      }

      return 'Please add at least one valid domain';
    }
  }

  get helpCenterSiteOptions() {
    return this.helpCenterService.sites.map((site) => ({
      text: site.name,
      value: Number(site.id),
    }));
  }

  get hasMultipleHelpCenterSites() {
    return this.helpCenterService.sites.length > 1;
  }

  get canSetHelpCenterSite() {
    return this.app.canUseMultipleHelpCenters && this.hasMultipleHelpCenterSites;
  }

  get paywallFeatureKey() {
    if (this.app.showMultiHelpCenterPaywalling) {
      return 'multi_help_center';
    }

    return '';
  }

  get isActiveBrandDefault() {
    return this.args.activeBrand.isDefault || this.args.activeBrand.defaultMobileBrand;
  }

  get canAddDomains() {
    return !this.args.isMobilePlatform;
  }

  get mobilePlatformOptions() {
    return [
      {
        text: 'iOS',
        value: 'ios',
      },
      {
        text: 'Android',
        value: 'android',
      },
    ];
  }

  @action
  addNewBrand() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked_add_new',
      object: 'messenger_brand',
      place: 'messenger_settings',
      section: 'messenger',
    });
    this.previousActiveBrand = this.args.activeBrand;
    let newBrand = this.args.brands.pushObject(
      this.store.createRecord('brand-settings', {
        brandName: '',
        brandDomains: [],
        logoUrl: this.defaultBrand.logoUrl,
        messengerBackgroundColor: this.defaultBrand.messengerBackgroundColor,
        messengerActionColor: this.defaultBrand.messengerActionColor,
        messengerWallpaper: this.defaultBrand.messengerWallpaper,
        launcherAlignment: this.defaultBrand.launcherAlignment,
        launcherHorizontalPadding: this.defaultBrand.launcherHorizontalPadding,
        launcherVerticalPadding: this.defaultBrand.launcherVerticalPadding,
        platform: this.args.isMobilePlatform ? 'mobile' : 'web',
        helpCenterId: Number(this.helpCenterService.defaultSite.id),
        mobileBundleId: '',
        mobilePlatform: this.args.isMobilePlatform ? 'ios' : undefined,
      }),
    );
    newBrand.addDomain('');
    this.args.setBrand(newBrand);
    this.showModal = true;
  }

  @action
  setActiveBrand(brand) {
    this.args.setBrand(brand);
    this.showAllDomains = false;
  }

  @action
  undoAndCloseModal() {
    let isCreating = this.isCreating;

    this.args.activeBrand.rollbackAttributes();

    if (isCreating) {
      this.args.setBrand(this.previousActiveBrand);
    }
    this.showModal = false;
  }

  @task
  *updateBrandAndCloseModal() {
    try {
      yield this.args.activeBrand.save();
      this.showModal = false;
      this.notificationsService.notifyConfirmation(
        `${this.args.activeBrand.brandName} was ${
          this.isCreating ? 'created' : 'saved'
        } successfully`,
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'save_and_set_live',
        object: 'messenger_brand',
        place: 'messenger_settings',
        section: 'messenger',
        metadata: {
          from_modal: true,
          is_new: this.isCreating,
          brand_id: this.args.activeBrand.id,
        },
      });
    } catch (error) {
      let message = this.intl.t(
        'messenger.look-and-feel-settings.brand-picker.default-error-message',
      );
      if (error.jqXHR.responseJSON.errors && error.jqXHR.responseJSON.errors[0]) {
        message = error.jqXHR.responseJSON.errors[0];
      }
      this.notificationsService.notifyError(message);
    }
  }

  @action
  addDomain() {
    this.args.activeBrand.addDomain('');
  }

  @action
  removeDomain(domain) {
    this.args.activeBrand.removeDomain(domain);
  }

  @action
  setHelpCenterSite(id) {
    this.args.activeBrand.helpCenterId = id;
  }

  @action
  setMobilePlatform(platform) {
    this.args.activeBrand.mobilePlatform = platform;
  }
}
