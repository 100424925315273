/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { sendIntercomUpdate } from 'embercom/lib/intercom-widget-helper';
import { all } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export default class Header extends Component {
  @service notificationsService;
  @service intl;
  @service appService;
  @service iamService;
  @service router;
  @service MessengerSettingsService;
  @service guideLibraryService;

  @tracked showEmptySlotGroupConfirmModal = false;

  get activeBrand() {
    return this.args.model.settings.lookAndFeel.activeBrand;
  }

  get homeSettings() {
    return this.args.model.settings.home;
  }

  get isDisabled() {
    return !this.args.model?.settings.languages.isValid;
  }

  get emptySlotGroup() {
    return this.homeSettings.slotGroups.find((s) => s.isEmpty);
  }

  get emptyHubSpaces() {
    if (this.emptySlotGroup.targetUserType === 'mobile_users') {
      return this.args.model.settings.spaces.spacesForMobileUsers;
    } else if (this.emptySlotGroup.targetUserType === 'users') {
      return this.args.model.settings.spaces.spacesForUsers;
    } else {
      return this.args.model.settings.spaces.spacesForVisitors;
    }
  }

  get emptyHub() {
    return this.emptyHubSpaces.find((space) => space.isHub);
  }

  get isCompact() {
    return !!this.args.model?.settings?.form?.isCompact;
  }

  get isMobileRouteAndHasEmptySlotGroup() {
    return (
      this.emptySlotGroup.targetUserType === 'mobile_users' &&
      this.router.currentRouteName === 'apps.app.messenger.mobile-sdk'
    );
  }

  get isWebRouteAndHasEmptySlotGroup() {
    return (
      this.emptySlotGroup.targetUserType !== 'mobile_users' &&
      this.router.currentRouteName === 'apps.app.messenger.web'
    );
  }

  get messengerProductTourId() {
    return 461229;
  }

  get activeRouteHasEmptySlotGroup() {
    return this.isMobileRouteAndHasEmptySlotGroup || this.isWebRouteAndHasEmptySlotGroup;
  }

  @action
  async submit() {
    if (
      !this.isCompact &&
      this.homeSettings.hasEmptySlotGroup &&
      this.emptyHub.enabled &&
      this.activeRouteHasEmptySlotGroup
    ) {
      this.showEmptySlotGroupConfirmModal = true;
    } else {
      await this.save.perform();
      if (this.guideLibraryService.canUseGuideLibraryService) {
        await this.guideLibraryService.markStepCompleted(
          'guide_library_foundational_steps_customise_messenger',
        );
      }
    }
  }

  @action
  showTour() {
    window.Intercom('startTour', this.messengerProductTourId);
  }

  @action
  showManageArticle() {
    window.Intercom('showArticle', 6612589); // https://www.intercom.com/help/en/articles/6612589-set-up-and-customize-the-messenger
  }

  @action
  openCustomizeMessengerCollecton() {
    safeWindowOpen(
      'https://www.intercom.com/help/en/collections/2094771-getting-started',
      '_blank',
    );
  }

  @task({ drop: true })
  *save() {
    yield this.beforeSave();
    try {
      yield this.saveSettings.perform();
      this.afterSave();
    } catch (e) {
      //Do nothing
    }
  }

  @task({ drop: true })
  *saveSettings() {
    try {
      yield this.args.model.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('messenger.settings-layout.header.success-notification'),
      );
    } catch (error) {
      if (error) {
        this.notificationsService.notifyResponseError(error, {
          default: this.intl.t('messenger.settings-layout.header.failure-notification'),
        });
      }
      throw error;
    }
  }

  @action
  async beforeSave() {
    this.homeSettings.slotGroups.forEach((slotgroup) => {
      slotgroup.resetSlotDisplayOrder(slotgroup, slotgroup.slots);
    });

    if (this.args.model.settings.security.hasUnsavedChanges) {
      this.args.model.settings.security.updateParsedAllowedDomains();
    }

    if (this.appService.app?.canUseMultipleHelpCenters) {
      let defaultBrand = this.args.model.settings.lookAndFeel.brands.find(
        (brand) => brand.isDefault,
      );
      if (defaultBrand.hasUnsavedChanges) {
        defaultBrand.copyM5SettingsToLookAndFeel();
      }
      await this.saveBrandsWithChanges.perform();
    } else if (this.activeBrand.hasUnsavedChanges) {
      if (this.activeBrand.backgroundType === 'image' && !this.activeBrand.backgroundImageUrl) {
        this.activeBrand.backgroundType = 'solid';
      }
      if (this.activeBrand.isDefault) {
        this.activeBrand.copyM5SettingsToLookAndFeel();
      } else {
        this.activeBrand.expandColors();
        await this.saveActiveBrand.perform();
      }
    }

    if (this.args.model.settings.lookAndFeel.hasUnsavedChanges) {
      this.args.model.settings.lookAndFeel.expandColors();
    }
  }

  @action
  afterSave() {
    this.homeSettings.slotGroups.forEach((slotGroup) => {
      slotGroup.removeExternalLinksWithoutIds();
      slotGroup.removeTicketLinksWithoutIds();
    });

    if (this.homeSettings.hasUnsavedChanges) {
      let deleteSlotsWithNoId = (slotGroup) => {
        slotGroup.slots.filterBy('id', null).forEach((slot) => slot.deleteRecord());
        slotGroup.set('slotsWereDeleted', false);
      };
      this.homeSettings.slotGroups.forEach(deleteSlotsWithNoId);

      this.homeSettings.slotGroups.forEach((slotGroup) => {
        slotGroup.resetExternalLinksWereDeleted();
        slotGroup.resetTicketLinksWereDeleted();
      });
    }
    let spaces = this.args.model.settings.spaces;
    this.args.model.settings.notifyPropertyChange('hasM5UnsavedChanges');
    if (this.iamService.companyId) {
      sendIntercomUpdate({
        can_manage_messenger_settings: true,
        company: {
          id: this.iamService.companyId,
          widget_version: 5,
          messenger_spaces_enabled_for_users: spaces.userEnabledSpacesForCda,
          messenger_spaces_enabled_for_visitors: spaces.visitorEnabledSpacesForCda,
          messenger_spaces_enabled_for_mobile_users: spaces.mobileUserEnabledSpacesForCda,
        },
      });
    }
  }

  @task
  *saveBrandsWithChanges() {
    let saveBrands = [];
    this.args.model.settings.lookAndFeel.brands
      .reject((brand) => brand.isDefault)
      .filter((brand) => brand.hasUnsavedChanges)
      .forEach((brand) => {
        brand.expandColors();
        saveBrands.push(taskFor(this.saveBrand).perform(brand));
      });

    yield all(saveBrands);
  }

  @task({ enqueue: true, maxConcurrency: 1 })
  *saveBrand(brand) {
    return yield brand.save();
  }

  @task({ drop: true })
  *saveActiveBrand() {
    yield this.activeBrand.save();
  }

  @action
  addApp() {
    this.MessengerSettingsService.navigate('content', 'add-apps');
    this.showEmptySlotGroupConfirmModal = false;
  }

  @action
  openLayoutTab() {
    this.settingsService.navigate('layout');
  }
}
