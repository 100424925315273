/* import __COLOCATED_TEMPLATE__ from './content.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class Content extends Component {
  @service('messenger-settings-service') settingsService;
  @service helpCenterService;
  @service appService;

  get hasMultipleHelpCenterSites() {
    return this.helpCenterService.sites.length > 1;
  }

  get canSetHelpCenterSite() {
    return this.appService.app?.canUseMultipleHelpCenters && this.hasMultipleHelpCenterSites;
  }
}
