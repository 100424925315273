/* import __COLOCATED_TEMPLATE__ from './privacy-policy-notice.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { BaseConfig } from '@intercom/embercom-prosemirror-composer';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import type Intl from 'embercom/services/intl';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';
import type PrivacyPolicyNoticeModel from 'embercom/models/messenger-settings/privacy-policy-notice';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

const MAX_CHARACTER_COUNT = 150;
class ParagraphOnlyComposerConfig extends BaseConfig {
  allowedBlocks = ['paragraph'];
  tools = [{ name: 'text-formatter' }, { name: 'anchor-editor' }];
  allowedInline = ['bold', 'italic', 'anchor'];
  allowTextAlignment = false;
  singleBlockMode = true;
  maxCharacterCount = MAX_CHARACTER_COUNT;
}

type SimpleLocale = { localeId: string; isPermitted: boolean; name: string };

type BlockObject = {
  localeName: string;
  localeId: string;
  blocksDocument: BlocksDocument;
};

export interface Args {
  privacyPolicyNotice: PrivacyPolicyNoticeModel;
  languages: {
    supportedLocales: Array<SimpleLocale>;
    defaultLocale: string;
  };
}

export default class PrivacyPolicyNotice extends Component<Args> {
  @service declare MessengerSettingsService: MessengerSettingsService;
  @service declare intl: Intl;

  @tracked
  blocksDocumentByLocale?: Array<BlockObject>;

  composerConfig: ParagraphOnlyComposerConfig;

  constructor(owner: unknown, args: never) {
    super(owner, args);

    this.composerConfig = new ParagraphOnlyComposerConfig();
    this.composerConfig.placeholder = this.intl.t(
      'messenger.settings-layout.privacy-policy-notice.text-entry-placeholder',
    );
    this.blocksDocumentByLocale = this.convertToBlockObjects(this.privacyPolicyNotice.contents);
  }

  get privacyPolicyNotice() {
    return this.args.privacyPolicyNotice;
  }

  get languages() {
    return this.args.languages;
  }

  private blocksDocument(paragraph: string) {
    return new BlocksDocument([{ type: 'paragraph', text: paragraph }]);
  }

  get permittedLocales() {
    let defaultLocale = this.languages.supportedLocales.findBy(
      'localeId',
      this.languages.defaultLocale,
    );

    let otherPermittedLocales = this.languages.supportedLocales.filter(
      (locale) => locale.localeId !== this.languages.defaultLocale && locale.isPermitted,
    );
    return [defaultLocale, ...otherPermittedLocales].compact();
  }

  convertToBlockObjects(contents: PrivacyPolicyNoticeModel['contents']): Array<BlockObject> {
    if (!contents) {
      return [];
    }

    let contentsForEnabledLocales =
      contents
        ?.filter((content) =>
          this.permittedLocales.map((locale) => locale?.localeId).includes(content.localeId || ''),
        )
        .compact() || [];

    let blocksForEnabledLocales = contentsForEnabledLocales.map((content) => {
      return {
        localeName:
          this.languages.supportedLocales.findBy('localeId', content.localeId)?.name || '',
        localeId: content.localeId || '',
        blocksDocument: this.blocksDocument(content.notice || ''),
      };
    });

    let blocksForNewLocales = this.permittedLocales
      .filter((locale) => {
        return !blocksForEnabledLocales.map((block) => block.localeId).includes(locale.localeId);
      })
      .map((locale) => {
        return {
          localeName: locale.name,
          localeId: locale.localeId,
          blocksDocument: this.blocksDocument(''),
        };
      });

    return blocksForEnabledLocales.concat(blocksForNewLocales);
  }

  @action
  onLanguageSpecificNoticeChange(localeId: string, blocksDocument: BlocksDocument) {
    return this.privacyPolicyNotice.updateContents(localeId, blocksDocument);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::General::PrivacyPolicyNotice': typeof PrivacyPolicyNotice;
    'messenger-settings/general/privacy-policy-notice': typeof PrivacyPolicyNotice;
  }
}
