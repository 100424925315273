/* import __COLOCATED_TEMPLATE__ from './snooze-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { SNOOZED_UNTIL_MAP, DIGITS_ONLY_REGEX } from 'embercom/lib/inbox/constants';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';

export default class SnoozeConversation extends Component {
  @service appService;
  @service session;
  @service logService;
  @service intl;
  @tracked selectedHours = 24;

  get app() {
    return this.appService.app;
  }

  get snoozeOptions() {
    return SNOOZED_UNTIL_MAP.map((snooze) => {
      snooze.value = this.intl.t(`inbox.manage-macros.snooze-conversation.map.${snooze.intlKey}`);
      return snooze;
    });
  }

  get actionData() {
    return this.args.action.actionData;
  }

  get snoozeString() {
    if (this.actionData.snoozed_until) {
      if (this.actionData.snoozed_until === 'hours') {
        return this.intl.t('inbox.manage-macros.snooze-conversation.snoozed-hours', {
          hours: this.actionData.selected_hours,
        });
      }

      return this.snoozeOptions.find((snooze) => snooze.key === this.actionData.snoozed_until)
        .value;
    }
  }

  get showCalculatedTimes() {
    return (
      this.args.actionEditorContext.mode === 'conversation' ||
      this.args.actionEditorContext.mode === 'bulk-conversation'
    );
  }

  @action snoozeByHours() {
    if (!DIGITS_ONLY_REGEX.test(this.selectedHours) || this.selectedHours <= 0) {
      this.selectedHours = '';
      return;
    }

    this.args.action.actionData = {
      snoozed_until: 'hours',
      author_timezone: moment.tz.guess(),
      selected_hours: this.selectedHours,
    };

    this.logService.logJSON({
      log_type: 'inboxMacrosEditorSnoozeConversation',
      app_id: this.session.workspace.id,
      selected_hours: this.selectedHours,
      moment_cache_js_author_timezone: moment.tz.guess(),
      moment_no_cache_js_author_timezone: moment.tz.guess(true),
      browser_author_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    if (this.onChange) {
      this.onChange();
    }
  }

  @action selectSnoozeByIndex(index) {
    this.args.action.actionData = {
      snoozed_until: this.snoozeOptions[index].key,
      author_timezone: moment.tz.guess(),
    };

    if (this.onChange) {
      this.onChange();
    }

    this.logService.logJSON({
      log_type: 'inboxMacrosEditorSnoozeConversation',
      app_id: this.session.workspace.id,
      snoozed_until: this.snoozeOptions[index].key,
      moment_cache_js_author_timezone: moment.tz.guess(),
      moment_no_cache_js_author_timezone: moment.tz.guess(true),
      browser_author_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }
}
