/* import __COLOCATED_TEMPLATE__ from './cardless-conversion-modal-pricing5.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */

import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import {
  BILLING_PERIODS,
  FIN_AI_COPILOT_BASE_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
  defaultSolutionId,
  findLatestPricing5PlanData,
} from 'embercom/lib/billing';
import { allPlanGroups, keyPlanGroups } from 'embercom/lib/conversion-modal-pricing5-plan-groups';
import RouteRegexes from 'embercom/lib/route-regexes';
import type Breakdown from 'embercom/models/billing/price/breakdown';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import type CardlessConversionModalService from 'embercom/services/cardless-conversion-modal-service';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';
import type IntlService from 'embercom/services/intl';
import type QuoteService from 'embercom/services/quote-service';
import type Plan from 'embercom/models/plan';

export default class NewCardlessConversionModal extends Component {
  @service declare appService: any;
  @service declare cardlessConversionModalService: CardlessConversionModalService;
  @service declare cardlessTrialService: CardlessTrialService;
  @service declare customerService: any;
  @service declare intl: IntlService;
  @service declare purchaseAnalyticsService: any;
  @service declare permissionsService: any;
  @service declare router: RouterService;
  @service declare quoteService: QuoteService;

  @tracked allFeaturesSelected = false;
  @tracked selectedBillingPeriod = this.defaultBillingPeriod;
  @tracked seatNumber: number | string = 1;
  defaultPlanIdForPrices = Number(PRICING_5_X_CORE_ADVANCED_ID);

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.seatNumber = this.appService.app?.humanAdminsWithCoreSeat.length || 1;
  }

  customerLogosRow1 = [
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/amazon.png') },
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/meta.png') },
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/atlassian.png') },
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/amplitude.png') },
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/microsoft.png') },
  ];

  customerLogosRow2 = [
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/coda.png') },
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/etoro.png') },
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/hrblock.png') },
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/inter.png') },
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/monzo.png') },
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/new_relic.png') },
  ];

  get defaultSolutionIdForPrices() {
    return Number(defaultSolutionId(PRICING_5_X_CORE_ADVANCED_ID));
  }

  get yearlyBillingSelected() {
    return this.selectedBillingPeriod === BILLING_PERIODS.Annual;
  }

  // Render state
  get isEligibleForAnnualPlan() {
    return this.cardlessConversionModalService.isEligibleForAnnualPlan;
  }

  get meetsShowModalCriteria() {
    return this.cardlessConversionModalService.showModal;
  }

  get showTrialHeader() {
    return this.cardlessTrialService.hasActiveTrialSubscriptionOrIsInCardlessTrial;
  }

  get shouldShowConversionModal() {
    return (
      (!this.isOnExcludeRoutesForConversionModal &&
        this.cardlessTrialService.hasActiveTrialSubscriptionOrIsInCardlessTrial &&
        this.meetsShowModalCriteria &&
        this.cardlessTrialService.isPricing5) ||
      (this.meetsShowModalCriteria && this.cardlessTrialService.noActiveSubOnP5)
    );
  }

  get showTrialExpiredView() {
    return this.cardlessTrialService.trialHasEnded;
  }

  get isOnExcludeRoutesForConversionModal() {
    return this.router.currentRouteName.match(RouteRegexes.excludedForCardlessConversionModal);
  }

  get supportFeatureGroups() {
    let metricPrices = {
      proactiveSupportPrice: this.fullProactiveAddonPrice,
      proactiveSupportBaseUsage: this.proactiveSupportBaseUsage,
      proactiveSupportMetricPrice: this.proactiveSupportMetricPrice,
      phonePrice: this.phoneMetricPrice,
      finMetricPrice: this.finMetricPrice,
      whatsAppMetricPrice: this.whatsAppMetricPrice,
      smsMetricPrice: this.smsMetricPrice,
      emailMetricPrice: this.emailMetricPrice,
      copilotPrice: this.copilotFormattedPrice,
      copilotDiscountPrice: this.copilotFormattedDiscountPrice,
    };

    let shouldShowCopilot = this.cardlessConversionModalService.displayCopilotInModal;

    return this.allFeaturesSelected
      ? allPlanGroups(metricPrices, shouldShowCopilot, this.yearlyBillingSelected)
      : keyPlanGroups(metricPrices, shouldShowCopilot, this.yearlyBillingSelected);
  }

  // Prices
  get quoteForMetrics() {
    return this.quoteService.getQuoteById(
      this.cardlessConversionModalService.planIdsForQuoteRetrieval,
    );
  }

  get isLoading() {
    return this.quoteService.loading;
  }

  get fullProactiveAddonPrice() {
    let dollarAmount = this.convertCentsToDollars(
      this.planQuoteBreakdown(Number(PROACTIVE_SUPPORT_PLUS_BASE_ID))?.amount,
    );

    return this.getFormattedPrice(dollarAmount);
  }

  get phoneMetricPrice() {
    return this.quoteForMetrics?.phoneMetricPrice(this.defaultSolutionIdForPrices);
  }

  get finMetricPrice() {
    return this.quoteForMetrics?.finMetricPrice(this.defaultPlanIdForPrices);
  }

  get emailMetricPrice() {
    return this.quoteForMetrics?.emailMetricPrice(this.defaultPlanIdForPrices);
  }

  get smsMetricPrice() {
    return this.quoteForMetrics?.smsMetricPrice(this.defaultPlanIdForPrices);
  }

  get whatsAppMetricPrice() {
    return this.quoteForMetrics?.whatsAppMetricPrice(this.defaultPlanIdForPrices);
  }

  get proactiveSupportMetricPrice() {
    return this.quoteForMetrics?.proactiveSupportMetricPrice;
  }

  get proactiveSupportBaseUsage() {
    return this.quoteForMetrics?.proactiveSupportBaseUsage;
  }

  get copilotDiscountPrice() {
    return (
      this.quoteForMetrics?.discountedAnnualSeatPrice(
        Number(FIN_AI_COPILOT_BASE_ID),
        Metric.copilot_seat_count,
      ) ?? 0
    );
  }

  get copilotFormattedDiscountPrice() {
    return this.getFormattedPrice(this.copilotDiscountPrice);
  }

  get copilotPrice() {
    return (
      this.quoteForMetrics?.fullSeatPrice(
        Number(FIN_AI_COPILOT_BASE_ID),
        Metric.copilot_seat_count,
      ) ?? 0
    );
  }

  get copilotFormattedPrice() {
    return this.getFormattedPrice(this.copilotPrice);
  }

  // Trial data
  get trialPlanName() {
    return this.intl.t(this.trialPlanData.nameTranslationKey);
  }

  get trialPlanData() {
    return findLatestPricing5PlanData(this.activeOrExpiredPlanTrialIds);
  }

  get activeOrExpiredPlanTrialIds() {
    return this.cardlessTrialService.activeOrExpiredPlanTrialIds;
  }

  get corePlans() {
    return this.appService.app.allPlansOnPricingModel.filter((plan: Plan) => !plan.product.addon);
  }

  get defaultBillingPeriod() {
    return this.appService.app?.teamPurchaseExperienceCheckoutDefaultAnnual
      ? BILLING_PERIODS.Annual
      : BILLING_PERIODS.Monthly;
  }

  @action
  onSeatNumberChange(value: number | string) {
    this.sendAnalyticsEvent({
      action: 'inputed',
      object: 'seat_number',
      extra: { seat_number: value },
    });
    this.seatNumber = value || 1;
  }

  @action
  updateBillingPeriod(period: BILLING_PERIODS) {
    this.sendAnalyticsEvent({
      action: 'selected',
      object: 'billing_period_selector',
      extra: { billing_period: period === BILLING_PERIODS.Annual ? 'annual' : 'monthly' },
    });
    this.selectedBillingPeriod = period;
  }

  @action
  sendAnalyticsEvent({ action, object, extra }: { action: string; object: string; extra?: any }) {
    this.purchaseAnalyticsService.trackEvent({
      action,
      object,
      context: 'cardless_trial',
      place: 'conversion_modal',
      ...(extra && { ...extra }),
    });
  }

  @action
  requestExtension() {
    this.sendAnalyticsEvent({ action: 'clicked', object: 'request_extension_button' });
  }

  @action
  onModalClose() {
    this.cardlessConversionModalService.hide();
  }

  @action
  onToggleComparePlans() {
    this.allFeaturesSelected = !this.allFeaturesSelected;
  }

  private planQuoteBreakdown(planId: number) {
    let quote = this.quoteService.getQuoteById(
      this.cardlessConversionModalService.planIdsForQuoteRetrieval,
    );
    return quote?.breakdown.find((quoteBreakdown: Breakdown) => quoteBreakdown.plan_id === planId);
  }

  private convertCentsToDollars(cents: number) {
    return cents / 100;
  }

  private getFormattedPrice(price: number) {
    return this.intl.formatNumber(price, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Modal::CardlessConversionModalPricing5': typeof NewCardlessConversionModal;
    'modal/cardless-conversion-modal-pricing5': typeof NewCardlessConversionModal;
  }
}
